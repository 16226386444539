import React from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "gatsby";
export default ({ data, props }) => {
  let testimonialTmpl = props.testimonialOneLine ? (
    <div class="flex-container center">
      <img
        src={data.userAvatar.url}
        alt={data.userAvatar.alt}
        class="customer-avatar"
      />
      <div class="name-customer">{data.userCompany}</div>
      <p class="small-copy">{data.roleCompany}</p>
    </div>
  ) : (
      <div class="client-testimonial-adaptive">
        <img
          src={data.userAvatar.url}
          alt={data.userAvatar.alt}
          class="customer-avatar"
        />
        <div class="client-testimonial-content">
          <div class="name-customer extended">{data.userCompany}</div>
          <p class="small-copy">{data.roleCompany}</p>
        </div>
      </div>
    );

  return (
    <div class={`white-section ${props.sectionClass}`}>
      <div class={`container ${props.containerClass}`}>
        <h2 class={`h2 ${props.h2Class}`}>{data.title}</h2>
        <div class="sm-spacing" />
        <p class={`p ${props.pClass}`}>
          <ReactMarkdown source={data.quote} escapeHtml={false} />
        </p>
        <div class="sm-spacing" />
        {testimonialTmpl}
        <div className="md-spacing" />
        {(data.cta || data.ctaCopy) && (
          <Link to={data.cta} class="link-text w-inline-block w-clearfix">
            <div class="learn-more">{data.ctaCopy}</div>
            <div class="arrow-right" />
          </Link>
        )}
        <div class={`product-image ${props.imageClass}`}>
          {data.photo && (
            <img
              className="shadow"
              src={data.photo.url}
              alt={data.photo.alt}
              sizes="(max-width: 991px) 100vw, 859px"
            />
          )}
          {/* <img
          src="images/nugit-list-tablet.jpg"
          alt="nugit-list"         
          sizes="(max-width: 991px) 90vw, 100vw"
          class="hide-desktop"
        /> */}
        </div>
      </div>
    </div>
  );
};
