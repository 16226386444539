import React from "react";
import FormatOutput from "../../styles/images/format-output.svg";

export default ({ data, props, idx }) => {  
  return (
    <React.Fragment>
      <section className={`white-section ${props.sectionClass}`}>
        {/* {idx === 1 && (
          <div class="export-format">
            <img src={FormatOutput} alt="stories-format" />
          </div>
        )} */}
        <div className={`container product ${props.containerClass}`}>
          <div className={`product-image ${props.imageClass}`}>
            {data.image && <img className={props.imageShadowClass ? "shadow":"" } src={data.image.url} alt={data.image.alt} />}
          </div>
          <div className="product-content">
            <h2 className={`h2 ${props.h2Class}`}>{data.title}</h2>
            <div className="sm-spacing" />
            <p className={`p ${props.pClass}`}>{data.subtitle}</p>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
