import React from "react";
import { isModelOfType } from "../../utils/common";
import Img from "gatsby-image";

export default ({ data }) => {
  let titleAndImage = data.find(item =>
    isModelOfType(item, "title_and_image")
  );
  let summaryAndImages = data.filter(item =>
    isModelOfType(item, "summary_with_image")
  );
    
  return (
    <React.Fragment>
      <section id="integration" className="white-section revert">
        <div className="container">
          {titleAndImage && (
            <React.Fragment>
              <div className="content-center">
                <h2 className="h2">{titleAndImage.title}</h2>
              </div>
              <div className="md-spacing" />
              {titleAndImage.image && (
                <Img
                  fluid={titleAndImage.image.fluid}
                  alt={titleAndImage.image.alt}
                />
              )}
            </React.Fragment>
          )}
          <div className="md-spacing" />
          <div className="grid-container-3">
            {summaryAndImages.map(item => (
              <div className="flex-container start" key={item.id}>
                <div
                  className="outline-icon"
                  style={{
                    backgroundPosition: "50% 50%",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${item.image && item.image.url})`
                  }}
                />
                <div className="content-w-outline-icon">
                  <div className="content-left center-m">
                    <h5 className="h4">{item.title}</h5>
                    <div className="tn-spacing" />
                    <p className="p">{item.subtitle}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
