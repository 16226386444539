import React from "react";

export default ({ data }) => {
  return (
    <React.Fragment>
      <section class="white-section reducedtop hide-mobile">
        <div class="container">
          <div class="grid-container-6">
            {data.map(item => (
              <div className="diagram-step" key={item.id }>
                {item.image && (
                  <img
                    src={item.image.url}
                    alt={item.image.alt}
                    sizes="(max-width: 634px) 95vw, 603px"
                  />
                )}
                <div class="sm-spacing" />
                <div class="content-center">
                  <h5>{item.title}</h5>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
