import React from "react";

export default ({ data, props }) => {
  return (
    <React.Fragment>
      <section className={`white-section ${props.sectionClass}`}>
        <div className={`container ${props.containerClass}`}>
          <h2 className={`${props.h2Class}`}>{data.title}</h2>
          <div className="sm-spacing" />
          <p className={`p ${props.pClass}`}>{data.subtitle}</p>
          <div className="md-spacing" />
          <div className={`product-image ${props.imageClass}`}>
            {data.image && (
              <img className="shadow" src={data.image.url} alt={data.image.alt} />
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
