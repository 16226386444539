import React from "react";
import { graphql } from 'gatsby'
import MainHeader from "../components/main-header";
import Section from "../components/product/productSection";
import SectionCenter from "../components/product/productSectionCenter";
import TestimonialSection from "../components/product/product-section-testimonial";
import TestimonialLeft from "../components/product/product-section-testimonial-left";
import CommonQuestion from "../components/common-question";
import { resetWebflow, isModelOfType } from "../utils/common";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Schema from "../components/common/schema";
import FeaturedVideo from "../components/highlight-video";
import VimeoLink from "../components/vimeo-link";
import Notification from "../components/header/notification";
import EnterpriseFeatures from "../components/product/enterprise-feature";
import ProductFeature from "../components/product/product-feature";
import Layout from "../layouts";

export default ({
  data: {
    datoCmsGlobalConfiguration: globalVideo,
    datoCmsProduct: {
      seoMetaTags,
      title,
      description: subTitle,
      productFeature,
      secondSection,
      enterpriseFeature,
      videoSectionTitle,
      videoSectionDescription,
      videos,
      commonQuestions,
      schema,
      notificationPage     
    },
    allDatoCmsNotification: { edges: notifications }
  }
}) => {
  resetWebflow("5aabd62c51e22e66a0346d12");

  const firstSectionProps = {
    headerClass: "product",
    buttonClass: "",
    heroClass: "h-fixedwidth",
    hideWhatIsNugitBtn: true,
    requestDemoClass: "tracking-demo-header-product"
  };
  const mainHeaderData = Object.assign({ title, subTitle });

  //second Section
  const secondSectionProps = [
    {
      sectionClass: "reducedtop",
      containerClass: "",
      h2Class: "h2",
      pClass: "",
      imageClass: "left",
      imageShadowClass: true
    },
    {
      sectionClass: "gray left-align",
      containerClass: "revert",
      h2Class: "h2",
      pClass: "",
      imageClass: "right",
      imageShadowClass: true
    },
    {
      sectionClass: "",
      containerClass: "revert",
      h2Class: "h2",
      pClass: "",
      imageClass: "right",
      imageShadowClass: false,
      showExplanation: true
    },
    {
      sectionClass: "black reducedbottom",
      containerClass: "center",
      h2Class: "h-fixedwidth",
      pClass: "center-align whiter",
      imageClass: "full",
      imageShadowClass: true
    },
    {
      sectionClass: "reducedbottom",
      containerClass: "center",
      h2Class: "h2",
      pClass: "center-align",
      imageClass: "full",
      imageShadowClass: true
    }
  ];

  let secondSectionSummaryWithImage = secondSection.filter(item => isModelOfType(item, "summary_with_image"));
  let secondSectionSummaryImageAndTestimonial = secondSection.filter(item => isModelOfType(item, "summary_image_and_testimonial"));

  const secondSectionSummaryWithImageTmpl = secondSectionSummaryWithImage.map((block, index) => {
    let template = [0,1,2].includes(index)  ? <Section data={block} props={secondSectionProps[index]} idx={index} /> : <SectionCenter idx={index} data={block} props={secondSectionProps[index++]} />;
    return template;
  });

  const secondSectionTestimonialProps = [
    {
      sectionClass: "reducedbottom",
      containerClass: "center",
      h2Class: "",
      pClass: "center-align",
      imageClass: "medium",
      testimonialClass: "center",
      testimonialOneLine: true
    },
    {
      sectionClass: "gray left-align",
      containerClass: "product revert",
      h2Class: "",
      pClass: "",
      imageClass: "right",
      testimonialOneLine: false
    },
    {
      sectionClass: "reducedbottom",
      containerClass: "product",
      h2Class: "",
      pClass: "",
      imageClass: "left",
      testimonialOneLine: false
    }
  ];

  const secondSectionSummaryWithTestimonial = secondSectionSummaryImageAndTestimonial.map((block, index) => {
    return index !== 0 ? <TestimonialLeft data={block} props={secondSectionTestimonialProps[index]} /> :  <TestimonialSection data={block} props={secondSectionTestimonialProps[index]} />;
  });

  return (
    <Layout>
      <Schema data={schema} />
      <Notification
        notifications={notifications}
        linkedPage={notificationPage}
      />
      <HelmetDatoCms seo={seoMetaTags} />
      <div className="tint-container">
        <div className="flex-container center">
          <div id="closevid" className="close-popup" />
          <VimeoLink key={globalVideo.video.providerUid} data={globalVideo}/>
        </div>
      </div>
      <MainHeader data={mainHeaderData} props={firstSectionProps} />
      <ProductFeature data={productFeature} />
      {secondSectionSummaryWithImageTmpl.slice(0,2)}     
      {secondSectionSummaryWithTestimonial}
      {secondSectionSummaryWithImageTmpl.slice(2)}
      <EnterpriseFeatures data={enterpriseFeature} />
      <FeaturedVideo data={videos} title={videoSectionTitle} subtitle={videoSectionDescription} />
      <section className="white-section gray">
        <div className="container">
          <CommonQuestion data={commonQuestions} />
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ProductQuery {
    datoCmsGlobalConfiguration {
      video: whatIsNugitVideo {
        providerUid
        url
      }
    }
    datoCmsProduct {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      description
      notificationPage {
        id
        page
      }
      productFeature {
        ... on DatoCmsTitleAndImage {
          title
          image {
            url
            alt
          }
          model {
            apiKey
          }
        }
      }
      secondSection {
        ... on DatoCmsSummaryWithImage {
          id
          title
          subtitle
          image {
            alt
            url
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsSummaryImageAndTestimonial {
          id
          title
          photo {
            alt
            url
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
          quote
          userAvatar {
            alt
            url
          }
          userCompany
          roleCompany
          cta
          ctaCopy
          model {
            apiKey
          }
        }
      }
      enterpriseFeature {
        ... on DatoCmsTitleAndImage {
          title
          image {
            url
            fluid {
              ...GatsbyDatoCmsFluid
            }
            alt
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsSummaryWithImage {
          id
          title
          subtitle
          image {
            url
            alt
          }
          model {
            apiKey
          }
        }
      }      
      videoSectionTitle
      videoSectionDescription
      videos {
        id
        slug
        title
        subtitle
        thumbnail {
          alt
          url
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      commonQuestions {
        ... on DatoCmsQuestion {
          id
          question
          answer
        }
      }      
      schema {
        ... on DatoCmsSchemaDetail {
          id
          jsonSchema
        }
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;
